@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');
.title--mainSize {
    font-size: xxx-large;
    font-weight: 600;
    font-family: 'Kanit', sans-serif;

}

.title--subSize{
    font-size: larger;
    font-weight: 500;

    font-family: 'Poppins', sans-serif;


}

.titleCard--wrapper {
    padding-top: 0.1vh;
    margin-top: 0.2vh;
    padding-bottom: 0.2vh;
    display: flex;
}