.SchoolInfo-Wrapper {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    margin-top: 2vh;


}
.schoolBox {
    display: flex;
    justify-content: center;
    align-items: center;


}
.SchoolInfo {
    flex: 1;
    text-align: center;
border: black;
    z-index:1;
    position: absolute;
    max-width: 75%;

    height: auto;

}
@media only screen and (max-device-width: 640px){
    .SchoolInfo{
    flex: 1;
    text-align: center;
    z-index:1;
    position: absolute;
    max-width: 75vw;
    min-width: 25vw;
    height: auto;

    transform: scale(0.4);
}
}
.schoolVideo {
    margin: auto;
    width: 100%;
    border-radius: 20px;
    object-fit: cover;
    border: red;

}

.page {


}
.edu {
    height: 100vh;
    background: linear-gradient(-35deg,  #9e86c8,  #e87d3e, #b4d273, #6c99bb);
    -webkit-animation: Animation 25s ease infinite;
    -moz-animation: Animation 25s ease infinite;
    animation: Animation 25s ease infinite;
    background-size: 300% 300%;
}
@-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}



.videoBanner{
    object-fit: cover;
    position:relative;
    z-index:0;

    aspect-ratio: 16/9;
    width: 90vw;
    max-height: 20vh;
    min-width: 100%;

}