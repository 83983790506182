.projPage{
    background: #518bff !important;
    height: 100%;
    width: 100%;
    overflow: auto;
}
.topShadow{
    width: 100%;
    position: sticky;
    top: -10px;
    height: 0vh;
    box-shadow: 0 0 20vh 2vh #03cafc;
    -webkit-box-shadow: 0 0 20vh 2vh #03cafc;
    z-index: 100;

}
.projHero {
    background: #fffcf6;
    height: 24vh;
    width: 100%;
    max-width: 100%;


}
.mainSection {
    background: #518bff !important;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    position: relative;
    align-items: center;
    background-size: cover;
    row-span: 2;
    overflow: hidden;



}
.cardimgContainer {

    margin-top: 1vh;
   margin-bottom: 0vh;

}
.cards{
    justify-content: center;
    align-self: stretch;
    height: auto;
    margin-top: 1vh;
    margin-bottom: 1vh;

    flex-grow: 1;

    flex-basis: 30%;
    background: #518bff;
    background-repeat: repeat;
    background-size: cover;


}